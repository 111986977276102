import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import PrimeVue from 'primevue/config';
import store from './store';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { createI18n } from 'vue-i18n';

//primevue global components
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dialog from 'primevue/dialog';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import Column from 'primevue/column';
import ProgressSpinner from 'primevue/progressspinner';
import Tooltip from 'primevue/tooltip';
import Dropdown from 'primevue/dropdown';
import InputNumber from 'primevue/inputnumber';

// import locale -- TODO: lazy loading by lang
import en from './locales/en.json';

import 'primevue/resources/themes/saga-blue/theme.css'; //theme
import 'primevue/resources/primevue.min.css'; //core css
import 'primeflex/primeflex.css';

import VueAppInsights from '@/plugins/vue-application-insights';

import '@/scss/main.scss';

const app = createApp(App);

const i18n = createI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages: { en }
});

app.use(store).use(router).use(PrimeVue).use(VueAxios, axios).use(ToastService).use(i18n);

app.use(VueAppInsights, {
  id: process.env.VUE_APP_INSIGHTS_KEY,
  router,
  baseName: 'VISTA',
  appInsightsConfig: {
    enableAutoRouteTracking: true,
    disableCookiesUsage: true,
    isStorageUseDisabled: false,
    disableTelemetry: false
  }
});

app.component('Dialog', Dialog);
app.component('Button', Button);
app.component('InputText', InputText);
app.component('Toast', Toast);
app.component('Column', Column);
app.component('ProgressSpinner', ProgressSpinner);
app.component('Dropdown', Dropdown);
app.component('InputNumber', InputNumber);

app.directive('tooltip', Tooltip);

app.mount('#app');
