import { createStore } from 'vuex';
import axios from 'axios';
import createPersistedState from 'vuex-persistedstate';
import { TableColumn, User, userSession } from '@/typings';
import shipments from './modules/shipments';
import filters from './modules/filters';
import alerts from './modules/alerts';
import LocalConfig from '@/scripts/localConfig';
import { colorThemes, UserRole } from '@/typings/enums';
import store from '.';
import router from '@/router';
import { TableColumns } from '@/constants/tableColumns';
import { authApi, searchApi, userApi, deliverySummariesApi, userLogoutApi } from '@/services/api';
import { OutOfOffice } from '@/services/model/user';

// set interceptor to catch session expiry on all calls.
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      store.commit('showDialogAlert', {
        text: 'Your session has expired or your credentials are not valid. Please try again.',
        header: 'Your session has expired',
        icon: null
      });
      store.commit('endSession');
      router.push('/');
    }
    // now catching all 500s, if that's annlying would need to remove or change this.
    if (error.response.status === 500 || error.response.status === 504) {
      store.commit('showDialogAlert', {
        text: `Some data could not be loaded. The services might be down or you have no internet access.`,
        header: 'Error',
        type: 'alert'
      });
    }
    return error;
  }
);

export const state = () => ({
  authorized: false,
  colorTheme: 'theme-blue-dark',
  loading: false,
  backgroundID: 0,
  userSession: {
    access_token: '',
    expires_in: 0
  } as userSession,
  userData: null as User | null,
  dialogAlert: {
    display: false,
    header: '',
    text: '',
    icon: 'attention', // default icon
    type: 'alert',
    actionNo: null,
    actionYes: null,
    buttonNo: 'No',
    buttonYes: 'Yes'
  },
  storedSearches: [] as any[],
  showFilters: false,
  showDetails: false,
  shipmentsTotal: 22
});

type State = ReturnType<typeof state>;

const mutations = {
  showDialogAlert(state, payload) {
    state.dialogAlert.header = payload.header || 'Error';
    state.dialogAlert.text = payload.text;
    state.dialogAlert.icon = payload.icon !== undefined ? payload.icon : 'attention';
    state.dialogAlert.display = true;
    state.dialogAlert.type = payload.type || 'alert';
    state.dialogAlert.actionNo = payload.actionNo || null;
    state.dialogAlert.actionYes = payload.actionYes || null;
    state.dialogAlert.buttonNo = payload.buttonNo || 'No';
    state.dialogAlert.buttonYes = payload.buttonYes || 'Yes';
  },
  hideDialogAlert(state) {
    state.dialogAlert.display = false;
  },
  saveSession(state, data) {
    state.userSession = data;
    state.authorized = true;
  },
  endSession(state) {
    state.userSession = {
      access_token: '',
      expires_in: 0
    };
    state.authorized = false;
    state.userData = null;
  },
  saveUserData(state, data: User) {
    state.userData = data;

    // update color theme in state if comes on user info
    const theme = data.settings.theme.themeData;
    if (theme && colorThemes[theme]) {
      state.colorTheme = theme;
    }
  },
  processLocalTableColumns(state, data) {
    if (state.userData) {
      // soft check to make sure all columns are coming otherwise we reset the columns
      if (data && TableColumns.length === data.length && data[0]) {
        const newColumns: TableColumn[] = [];
        // loop trough the columns and pick the values, will skip non existing columns in case columns have changed
        // Here we fill up the loaded data with the data we have in the constant. Static values are not saved.
        TableColumns.forEach((el) => {
          const loadedEl = data.find((f) => f.field === el.field);
          if (loadedEl) {
            newColumns.push({
              field: el.field,
              property: el.property ? el.property : undefined,
              visible: loadedEl.visible,
              order: loadedEl.order,
              width: loadedEl.width,
              sortStr: el.sortStr ? el.sortStr : undefined,
              path: el.path,
              dqTeamEdit: el.dqTeamEdit
            });
          } else {
            newColumns.push(el);
          }
        });
        state.userData.settings.theme.tableListColumns = newColumns;
      } else {
        state.userData.settings.theme.tableListColumns = TableColumns;
      }
    }
  },
  setShowFilters(state, value: boolean) {
    state.showFilters = value;
  },
  setShowDetails(state, value: boolean) {
    state.showDetails = value;
  },
  setLoading(state, value: boolean) {
    state.loading = value;
  },
  setTheme(state, theme) {
    state.colorTheme = theme;
  },
  setColumnsData(state, obj) {
    if (state.userData) {
      state.userData.settings.theme.tableListColumns = obj;
    }
  },
  changeColumnSize(state, obj) {
    const minColWidth = 40;
    if (state.userData) {
      const cols = state.userData.settings.theme.tableListColumns;
      const colIndex = cols.findIndex((f) => f.field === obj.column);
      const newWidth = cols[colIndex].width + obj.displacement * -1;
      if (newWidth > minColWidth) {
        state.userData.settings.theme.tableListColumns[colIndex].width = newWidth;
      }
    }
  },
  setFollowedDeliveries(state, data) {
    if (state.userData) {
      state.userData.followedDeliveries = data;
    }
  },
  restoreFollowedDeliveries(state, id) {
    if (state.userData) {
      state.userData.followedDeliveries.push(id);
      store.commit('showDialogAlert', {
        text: 'You have reached the maximum allowed amount for tracking deliveries. Please remove at least one delivery and try again.',
        header: 'It is no possible to follow more deliveries',
        icon: null
      });
      setTimeout(() => {
        if (state.userData) {
          state.userData.followedDeliveries = state.userData.followedDeliveries.filter((delivery) => delivery !== id);
        }
      }, 100);
    }
  },
  setStoredSearches(state, data) {
    if (!state.storedSearches.find((f) => f.id === data.id && f.fieldName === data.fieldName)) {
      state.storedSearches.unshift(data);
    }
    // limit array to 5 items
    if (state.storedSearches.length > 5) {
      state.storedSearches.pop();
    }
  }
};

export const getters = {
  getBackgroundID(state) {
    if (state.backgroundID === 0) {
      const newId = Math.floor(Math.random() * 5) + 1;
      state.backgroundID = newId;
    }
    return state.backgroundID;
  },
  isDQTeamAuthorized(state: State): boolean {
    return (state.userData?.settings.roles ?? []).includes(UserRole.DQ_OFFICER);
  }
};

const actions = {
  // gets token from returned code
  GET_TOKEN: async ({ commit, dispatch }, code) => {
    if (location.hostname === 'localhost' && LocalConfig.LOCALHOST_TOKEN !== '') {
      // hack for localhost when token is not empty
      return await dispatch('LOCAL_AUTH');
    } else {
      const redirectUri: string = process.env.VUE_APP_VISTA_BASE_URL;
      return await authApi
        .token(code, redirectUri)
        .then(async (resp) => {
          const filteredResponse = (({ access_token, expires_in }) => ({
            access_token,
            expires_in
          }))(resp.data);

          commit('saveSession', filteredResponse);
          axios.defaults.headers.common['Authorization'] = `bearer ${filteredResponse.access_token}`; // update axios header config

          await dispatch('GET_USER');
          dispatch('AUTO_REFRESH', {
            token: resp.data.refresh_token,
            expiry: resp.data.expires_in
          });
          return filteredResponse;
        })
        .catch((error) => {
          console.log(error);
          return false;
        });
    }
  },

  // Force login when working in localhost
  LOCAL_AUTH: async ({ commit, dispatch }, code) => {
    const data = {
      access_token: LocalConfig.LOCALHOST_TOKEN,
      expires_in: 15000,
      refresh_token: '',
      refresh_expires_in: 1800
    };
    const sessionData = (({ access_token, expires_in }) => ({
      access_token,
      expires_in
    }))(data);
    commit('saveSession', sessionData);
    axios.defaults.headers.common['Authorization'] = `bearer ${sessionData.access_token}`; // update axios header config

    await dispatch('GET_USER');
    dispatch('AUTO_REFRESH', {
      token: data.refresh_token,
      expiry: data.expires_in
    });
    return sessionData;
  },

  // refreshes access token or ends session
  REFRESH_TOKEN: async ({ commit, dispatch }, code) => {
    const sessionData = await authApi
      .tokenRefresh(code)
      .then((resp) => {
        const filteredResponse = (({ access_token, expires_in }) => ({
          access_token,
          expires_in
        }))(resp.data);

        commit('saveSession', filteredResponse); // save login to store
        axios.defaults.headers.common['Authorization'] = `bearer ${filteredResponse.access_token}`; // update axios header config

        dispatch('AUTO_REFRESH', {
          token: resp.data.refresh_token,
          expiry: resp.data.expires_in
        });

        console.log('session refreshed');
      })
      .catch((error) => {
        console.log('Session Expired');
        store.commit('showDialogAlert', {
          text: 'Your session has expired. Please login again.',
          header: 'Your session has expired',
          icon: null
        });
        router.push('/');
        commit('endSession');
      });
  },

  // will call refresh 1 min before session expires.
  AUTO_REFRESH: ({ dispatch }, data) => {
    const refreshTimer = (data.expiry - 60) * 1000;
    const token = data.token;
    setTimeout(() => dispatch('REFRESH_TOKEN', token), refreshTimer);
  },

  //get user data
  GET_USER: async ({ commit, dispatch }) => {
    await userApi
      .getOrCreateUser()
      .then(async (resp) => {
        await dispatch('filters/GET_FILTERS');
        commit('saveUserData', resp.data);
        commit('filters/loadPreviousFilterConditions', resp.data?.settings?.appliedFilter);
        commit(
          'processLocalTableColumns',
          resp.data.settings?.theme?.tableListColumns ? resp.data?.settings?.theme?.tableListColumns : false
        );
      })
      .catch((error) => {
        console.log('Error retrieving user info: ' + error);
        commit('endSession');
      });
  },

  SAVE_TABLE_SETTINGS: async ({ commit }, data) => {
    return await userApi
      .saveTableColumns(data)
      .then((resp) => {
        commit('saveUserData', resp.data);
        commit(
          'processLocalTableColumns',
          resp.data.settings?.theme?.tableListColumns ? resp.data?.settings?.theme?.tableListColumns : false
        );

        return true;
      })
      .catch((error) => {
        console.log('Error retrieving saving table info: ' + error);
        return false;
      });
  },

  SAVE_NOTIFICATIONS_SETTINGS: ({ commit }, data) => {
    userApi
      .saveNotifications(data)
      .then((resp) => {
        commit('saveUserData', resp.data);
        commit(
          'processLocalTableColumns',
          resp.data.settings?.theme?.tableListColumns ? resp.data.settings?.theme?.tableListColumns : false
        );
      })
      .catch((error) => {
        console.log('Error saving notifications: ' + error);
      });
  },

  SAVE_OOO: ({ commit }, data: OutOfOffice) => {
    console.log(data);
    userApi
      .saveOutOfOffice(data)
      .then((resp) => {
        commit('saveUserData', resp.data);
        commit(
          'processLocalTableColumns',
          resp.data?.settings?.theme?.tableListColumns ? resp.data?.settings?.theme?.tableListColumns : false
        );
      })
      .catch((error) => {
        console.log('Error saving Out of office settings: ' + error);
      });
  },

  SAVE_THEME: ({ commit }, data) => {
    userApi
      .saveThemeData(data)
      .then((resp) => {
        commit('saveUserData', resp.data);
        commit(
          'processLocalTableColumns',
          resp.data.settings?.theme?.tableListColumns ? resp.data?.settings?.theme?.tableListColumns : false
        );
      })
      .catch((error) => {
        console.log('Error saving theme settings: ' + error);
      });
  },

  SAVE_TIMEZONE: ({ commit }, data) => {
    userApi
      .saveTimezone(data)
      .then((resp) => {
        commit('saveUserData', resp.data);
        commit(
          'processLocalTableColumns',
          resp.data.settings?.theme?.tableListColumns ? resp.data.settings?.theme?.tableListColumns : false
        );
      })
      .catch((error) => {
        console.log('Error saving timezone settings: ' + error);
      });
  },

  SET_SHOW_FILTERS: ({ commit }, value: boolean) => {
    commit('setShowFilters', value);
  },
  SET_SHOW_DETAILS: ({ commit }, value: boolean) => {
    commit('setShowDetails', value);
  },
  LOGOUT: async ({ commit }) => {
    const logoutUser = await userLogoutApi
      .logout()
      .then(async () => {
        commit('endSession');
      })
      .catch((error) => {
        console.log('Error when logout: ' + error);
        commit('endSession');
      });
  },
  // detail view calls
  GET_DETAIL_MASTER_DATA: async ({ commit }, id: string) => {
    const item = await deliverySummariesApi
      .findSummaryByDeliveryNumber(id)
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log('Error retrieving item data: ' + error);
      });
    return item;
  },

  // detail view calls
  GET_DETAIL_TIMELINE: async ({ commit }, id: string) => {
    const item = await deliverySummariesApi
      .findTimelineByDeliveryNumber(id)
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log('Error retrieving timeline: ' + error);
      });
    return item;
  },

  // provide a general message when backend call fails
  GLOBAL_ERROR: ({ commit }) => {
    store.commit('showDialogAlert', {
      text: `An error prevented the data to be loaded. The services might not be working properly or you have no internet access.`,
      header: 'Error',
      type: 'alert'
    });
  },

  // Perform a global search
  SEARCH: async ({ commit }, data: string) => {
    return await searchApi
      .search(data, 15)
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log('Error doing search ' + error);
        return false;
      });
  }
};

export default createStore({
  plugins: [
    createPersistedState({
      key: 'Vista',
      storage: window.sessionStorage
    })
  ],
  state: state,
  mutations: mutations,
  getters: getters,
  actions: actions,

  modules: {
    shipments,
    filters,
    alerts
  }
});
