// the following enums map the frontend values to the correspondent backend values for filter API calls
// the reason of this is the constant changes in backend naming
// they are separated to be able to loop easily through them into the different component sections.

import { ShipmentStageSegmentLineSubTypeEnum, ShipmentStageSegmentLineTypeEnum } from '@/services/model/vista';

export enum FilterType {
  TIME = 'time',
  ORIGIN = 'origin',
  DESTINATION = 'destination',
  STATE = 'state',
  CARRIERS = 'carriers',
  STATUS = 'status',
  PARTNER_ID = 'partners',
  PRIMARY_MODE = 'primaryMode',
  BUSINESS_UNIT = 'sbus',
  PRODUCT = 'material',
  ADDITIONAL_FIELDS = 'additionalFields',
  DQ_FILTER = 'dq2'
}

export enum FilterOrigins {
  regionOrigin = 'originRegionCode',
  countryOrigin = 'originCountryCode',
  companyOrigin = 'origin'
}

export enum FilterDestinations {
  regionDestination = 'destinationRegionCode',
  countryDestination = 'destinationCountryCode',
  companyDestination = 'destination'
}

export enum FilterTime {
  actualDelivery = 'ataPortOfDischarge',
  shimpentStart = 'actualShipmentStart',
  carrierETA = 'etaPortOfDischarge',
  calculatedETA = 'calculatedETA',
  promisedBy = 'confirmedDeliveryDate'
}

export enum StageEventType {
  ETA = 'ETA',
  ATA = 'ATA',
  ETD = 'ETD',
  ATD = 'ATD'
}

export enum FilterAdditional {
  shipmentId = 'shipmentIds',
  billOfLanding = 'billOfLadingNumber',
  poNumber = 'purchaseOrderNumber',
  soNumber = 'salesOrderNumber',
  deliverID = 'deliveryNumber',
  houseAirwBill = 'airwayBillNumber',
  masterAirwBill = 'masterAirWaybill',
  containerNO = 'containerId',
  vesselNO = 'vesselImo',
  vesselName = 'vesselName',
  voyageId = 'voyageId',
  bookingNo = 'bookingNumber',
  flightNO = 'flightNumber',
  incoterms1 = 'incotermCode',
  incoterms2 = 'incotermLocation',
  logisticTrackerID = 'id'
}

// end names map

export const TimelineEventLongTexts: Record<ShipmentStageSegmentLineTypeEnum, string | null> = {
  ATA: 'Actual time of arrival',
  ATD: 'Actual time of departure',
  ETA: 'Estimated time of arrival',
  ETD: 'Estimated time of departure',
  OTHER: null,
  TECHNICAL_CLOSED: null
};

export const TimelineEventShortTexts: Record<ShipmentStageSegmentLineTypeEnum, string | null> = {
  ATA: 'ATA',
  ATD: 'ATD',
  ETA: 'ETA',
  ETD: 'ETD',
  TECHNICAL_CLOSED: 'Technically Closed',
  OTHER: null
};

export const TimelineSubEventTexts: Record<ShipmentStageSegmentLineSubTypeEnum, string> = {
  UNLOADED_FROM_RAIL: 'Unloaded from rail',
  LOADED_ON_RAIL: 'Loaded on rail',
  DEPARTURE_FROM_RAILWAY_STATION: 'Departure from railway station',
  LOADED_ON_VESSEL: 'Loaded on vessel',
  GATE_IN_TERMINAL: 'Gate in terminal',
  GATE_OUT_TERMINAL: 'Gate out terminal',
  UNLOADED_FROM_VESSEL: 'Unloaded from vessel',
  EN_ROUTE_TO_PICKUP_LOCATION: 'En Route to Pick-up Location',
  ARRIVED_AT_PICKUP_LOCATION: 'Arrived at Pick-up Location',
  COLLECTION_PICKUP_COMPLETED: 'Collection/pick-up Completed',
  DEPARTED_PICKUP_LOCATION: 'Departed Pick-up Location',
  EN_ROUTE_TO_DELIVERY_LOCATION: 'En Route to Delivery Location',
  EN_ROUTE: 'En Route',
  SHIPMENT_DELAYED: 'Shipment Delayed',
  DELAYED_IN_TRANSIT: 'Delayed In Transit',
  ETA_DELIVERY_AT_RISK: 'ETA Delivery at risk',
  ETA_DELIVERY: 'ETA Delivery',
  EN_ROUTE_TO_TERMINAL_LOCATION: 'En Route to Terminal Location',
  READY_FOR_PICKUP_AT_ARRIVAL_TERMINAL: 'Ready for Pick-up at Arrival Terminal',
  PICKED_UP_AT_ARRIVAL_TERMINAL: 'Picked-up at Arrival Terminal',
  DROPPED_AT_DEPARTURE_TERMINAL: 'Dropped at Departure Terminal',
  DEPARTED_FROM_DEPARTURE_TERMINAL: 'Departed from Departure Terminal',
  ARRIVED_AT_DELIVERY_LOCATION: 'Arrived at Delivery Location',
  DEPARTED_DELIVERY_LOCATION: 'Departed Delivery Location',
  DELIVERY_COMPLETED: 'Delivery Completed',
  DELIVERY_NOT_COMPLETED: 'Delivery Not Completed',
  DELIVERED_INCORRECTLY: 'Delivered Incorrectly',
  LATEST_APPOINTMENT_CHANGE: 'Latest Appointment Change',
  COMPLETED_LOADING_AT_PICKUP_LOCATION: 'Completed Loading at Pickup Location',
  ESTIMATED_DATE_AND_OR_TIME_OF_ARRIVAL_AT_CONSIGNEE: 'Estimated Date and/or Time of Arrival at Consignee',
  COMPLETED_UNLOADING_AT_DELIVERY_LOCATION: 'Completed Unloading at Delivery Location',
  CARRIER_DEPARTED_DELIVERY_LOCATION: 'Carrier Departed Delivery Location',
  CARRIER_DEPARTED_PICK_UP_LOCATION: 'Carrier Departed Pick-up Location'
};

export enum FilterAction {
  ADD,
  REMOVE
}

export enum TransportType {
  TRUCK = 'ROAD',
  MAIL = 'ROAD',
  TRAIN = 'TRAIN',
  SEA = 'SHIP',
  PLANE = 'PLANE',
  PIPELINE = 'OTHER',
  OTHER = 'OTHER'
}

export enum CarrierStatus {
  ON_TIME = 'On time',
  DELAY = 'Late',
  EARLY = 'Early'
}

export enum ShipmentStatus {
  BOOKED = 'Booked',
  IN_TRANSIT = 'In transit',
  DELIVERED = 'Delivered',
  CLOSED = 'Technically Closed',
  CANCELLED = 'Cancelled',
  NA = 'Status not available'
}
//TODO: replace with condition ids and i18n key
export enum AlertConditions {
  'type1' = 'ATA at Port of Loading or Waypoint is later than initial ETA',
  'type2' = 'ATD at Port of Loading or Waypoint is later than initial ETD',
  'type3' = 'ETA Port of Discharge is later than Confirmed Delivery Date'
}

export enum tableColumnsHeaders {
  origin = 'Origin Plant',
  destination = 'Ship To Destination',
  originCountry = 'Origin Country',
  destinationCountry = 'Destination Country',
  deliveryNumber = 'Delivery',
  purchaseOrderNumber = 'Purchase Order',
  salesOrderNumber = 'Sales Order',
  lateness = 'Lateness',
  reasonCode = 'Reason Code',
  reason = 'Reason Description',
  primaryMode = 'Primary Mode',
  specialProcessingIndicator = 'Special Processing Indicator',
  equipmentType = 'Equipment Type',
  preLegCarrier = 'SP Carrier Preleg',
  mainDirectLegCarrier = 'SP Carrier Mainleg',
  state = 'State',
  shipmentNumber = 'Shipment',
  shipmentStart = 'Shipment Start',
  confirmedDeliveryDate = 'Confirmed Delivery Date',
  etaPortOfDischarge = 'ETA Port of Discharge',
  atdPortOfLoading = 'ATD Port of Loading',
  sbus = 'Business Unit',
  containerId = 'Container No',
  flightNumber = 'Flight No',
  airwayBillNumber = 'House Airway Bill',
  masterAirWaybill = 'Master Airway Bill',
  materialNumber = 'Material ID',
  materialDescription = 'Material Description',
  vesselIMO = 'Vessel IMO',
  vesselName = 'Vessel Name',
  voyageId = 'Voyage ID',
  etdPortOfLoading = 'ETD Port of Loading',
  bookingNumber = 'Booking Number',
  incotermCode = 'Incoterm 1',
  incotermLocation = 'Incoterm 2',
  billOfLadingNumber = 'Bill of Lading',
  ataPortOfDischarge = 'ATA Port of Discharge',
  SP = 'SP Carrier',
  TB = 'TB Carriers Agent',
  WE = 'WE Ship to Party',
  '9A' = '9A Requisitioner',
  '9W' = '9W Good Recipient',
  '9O' = '9O Operational Officer',
  AS = 'AS Order Clerk',
  AM = 'AM Order Manager',
  Z5 = 'Z5 Transportation Planner',
  Z6 = 'Z6 Strategic Customer',
  VE = 'VE Sales Employee',
  CO = 'CO Consignee',
  TS = 'TS Fowarding Agent',
  NY = 'NY Notify 1st Costumer',
  TD = 'TD Preleg Shipment Carrier',
  TT = 'TT Subcontractor Preleg',
  SI = 'SI Global Isotank Operator',
  unloadingPoint = 'Unloading Point',
  meansOfTransportId = 'Means of Transport ID'
}

export enum colorThemes {
  'theme-blue-dark' = 'theme-blue-dark',
  'theme-blue-light' = 'theme-blue-light',
  'theme-green-dark' = 'theme-green-dark',
  'theme-green-light' = 'theme-green-light',
  'theme-red' = 'theme-red',
  'theme-orange' = 'theme-orange',
  'theme-grey' = 'theme-grey'
}

export enum UserRole {
  DQ_OFFICER = 'DQ_OFFICER'
}
